<template>

    <table class="b-table" cellpadding="0" cellspacing="0">
        <tr>
            <th rowspan="2">航班号</th>
            <th rowspan="2">出发机场</th>
            <th rowspan="2">到达机场</th>
            <th rowspan="2">航班日期</th>
            <th rowspan="2">航班状态</th>
            <th rowspan="2">航班是否延误</th>
            <th rowspan="2" v-if="type=='prev'">最少过站时间</th>
            <th colspan="4">起飞时间 </th>
            <th colspan="4">到达时间</th>
        </tr>
        <tr>
            <th>计划起飞</th>
            <th>官方预计起飞</th>
            <th>撤轮挡</th>
            <th>实际起飞</th>
            <th>计划到达</th>
            <th>官方预计到达</th>
            <th>上轮挡 </th>
            <th>实际到达</th>
        </tr>
        <tr>
            <td>{{flight.fnum||'--'}}</td>
            <td>
                <div class="airport ">
                    <p class="name  flex flex-yc flex-xc">{{flight.depName||'--'}}</p>{{flight.depCode||'--'}}
                </div>
            </td>
            <td>
                <div class="airport">
                    <p class="name ">{{flight.arrName||'--'}}</p>{{flight.arrCode||'--'}}
                </div>
            </td>
            <td>{{flight.date||'--'}}</td>
            <td>{{flight.state||'--'}}</td>
            <td>{{flight.delayTag||'--'}}</td>
            <td v-if="type=='prev'">{{flight.passTime||'--'}}</td>
            <td>{{flight.depPlanTime||'--'}}</td>
            <td>{{flight.depReadyTime||'--'}}</td>
            <td>{{flight.aobt||'--'}}</td>
            <td>{{flight.depTime||'--'}}</td>
            <td>{{flight.arrPlanTime||'--'}}</td>
            <td>{{flight.arrReadyTime||'--'}}</td>
            <td>{{flight.aibt||'--'}}</td>
            <td>{{flight.arrTime||'--'}}</td>
        </tr>
    </table>

</template>
<script>
    export default {
        props: {
            flight: {
                type: Object,
            },
            type: {
                type: String,
                default: ''
            },

        },


    }

</script>